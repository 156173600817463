<template>
  <div class="content">
    <img src="@/assets/customer/header.jpg" alt="顶部" class="top-img" />

    <div class="sub-form">
      <div class="form-part">
        <div class="part-title">就餐预约</div>
        <EInput
          title="姓名"
          :col="7"
          v-model:value="modelRef.name"
          :validate="validateInfos.name"
        />
        <ERadio
          title="性别"
          :col="7"
          v-model:value="modelRef.sex"
          :options="sexes"
        />
        <EInput
          title="手机号"
          :col="7"
          v-model:value="modelRef.mobile"
          :validate="validateInfos.mobile"
        />

        <ESelect
          title="选择餐标"
          :col="7"
          v-model:value="modelRef.comboPrice"
          :validate="validateInfos.comboPrice"
          :options="comboUnit"
          @ok="onChangeComboUnit"
        />

        <ESelect
          title="就餐人数"
          :col="7"
          v-model:value="modelRef.peopleCount"
          :validate="validateInfos.peopleCount"
          :options="peoples"
          @ok="onChangePeoples"
        />

        <ESelect
          title="选择套餐"
          :col="7"
          v-model:value="combo"
          :options="combos"
          @ok="onChangeCombo"
        />
      </div>

      <div class="form-part">
        <EDateTime
          title="预约时间"
          :col="7"
          v-model:value="modelRef.bookTime"
          :validate="validateInfos.bookTime"
        />
      </div>

      <div class="form-part">
        <EInput
          title="备注"
          :col="7"
          v-model:value="modelRef.remark"
        />
      </div>

      <div class="sub-btn-box">
        <div class="sub-btn" @click="onSubScribe">立即预约</div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import { defineComponent, reactive, toRaw, ref } from 'vue'
import Footer from './Footer'
import EInput from '@/components/customer/form/Input'
import ESelect from '@/components/customer/form/Select'
import EDateTime from '@/components/customer/form/DateTime'
import ERadio from '@/components/customer/form/Radio'
import { message } from 'ant-design-vue'
import { OrderClass } from '@/apis/order'
import defaultConfig from '@/config/default.config'
import router from '@/router'

const order = new OrderClass()
export default defineComponent({
  components: {
    Footer,
    EInput,
    ESelect,
    EDateTime,
    ERadio
  },

  setup () {
    const allCombos = ref([])
    const combos = ref([])
    const combo = ref('')
    const comboUnit = ref([])
    const peoples = ref([])

    const modelRef = reactive({
      name: '',
      sex: 1,
      mobile: '',
      peopleCount: '',
      comboPrice: '',
      bookTime: ''
    })

    const validateInfos = reactive({
      mobile: {
        required: true,
        message: '请输入手机号'
      },
      name: {
        required: true,
        message: '请输入姓名'
      },
      peopleCount: {
        required: true,
        message: '请选择人数'
      },
      comboPrice: {
        required: true,
        message: '请选择餐标'
      },
      bookTime: {
        required: true,
        message: '请选择日期'
      }
    })

    const validate = () => {
      return new Promise((resolve, reject) => {
        const formData = toRaw(modelRef)
        for (const key in formData) {
          if (validateInfos[key] && validateInfos[key].required) {
            if (!formData[key]) {
              validateInfos[key].error = true
            } else {
              delete validateInfos[key].error
            }
          }
        }

        const errors = {}
        for (const key in validateInfos) {
          if (validateInfos[key].error) {
            errors[key] = validateInfos[key]
          }
        }

        if (Object.keys(errors).length > 0) {
          reject(errors)
        }

        resolve(modelRef)
      })
    }

    const submiting = ref(false)
    const onSubScribe = () => {
      if (submiting.value) {
        message.error('正在提交')
        return false
      }
      submiting.value = true
      validate()
        .then(() => {
          const data = toRaw(modelRef)
          data.storeId = defaultConfig.store_id
          data.comboId = getComboId()
          console.log(data)

          if (data.mobile.length !== 11) {
            message.error('手机号码格式错误')
            return
          }

          order.book(data).then(resp => {
            console.log(resp)
            submiting.value = false
            router.push({ name: 'customer-orders' })
          }).catch(() => {
            submiting.value = false
          })
        })
        .catch((e) => {
          Object.values(e).map((x) => {
            message.error(x.message)
            submiting.value = false
          })
        })
    }

    const getComboId = () => {
      const { comboPrice, peopleCount } = toRaw(modelRef)

      let a = allCombos.value.filter(
        (x) => x.unitPrice === Number(comboPrice) && x.peopleCount === Number(peopleCount)
      )
      console.log(a)
      a = a.filter(x => x.name === combo.value)
      const b = a.length > 0 ? a[0].id : 0
      return b
    }

    const onChangeCombo = (e) => {
      console.log(e)
    }

    const onChangeComboUnit = (e) => {
      const all = allCombos.value.filter((x) => x.unitPrice === Number(e))
      const comboList = allCombos.value.filter((x) => x.unitPrice === Number(e))

      combo.value = ''

      const selectCombos = []
      const selectPeoples = []
      const selectCombosV = []
      const selectPeoplesV = []
      all.map((e) => {
        if (!selectPeoples.includes(e.peopleCount)) {
          selectPeoples.push(e.peopleCount)
        }
      })

      comboList.map(e => {
        if (!selectCombos.includes(e.name)) {
          selectCombos.push(e.name)
        }
      })

      selectPeoples.map(e => {
        selectPeoplesV.push({
          label: e + '人',
          value: e
        })
      })

      selectCombos.map(e => {
        selectCombosV.push({
          label: e,
          value: e
        })
      })

      console.log(selectPeoplesV)
      peoples.value = selectPeoplesV
      combos.value = selectCombosV
    }

    const onChangePeoples = (e) => {
      const all = allCombos.value.filter((x) => x.peopleCount === Number(e))
      const comboList = allCombos.value.filter((x) => x.unitPrice === Number(modelRef.comboPrice))

      combo.value = ''
      const selectCombos = []
      const selectComboUnits = []
      const selectCombosV = []
      const selectComboUnitsV = []
      all.map((e) => {
        if (!selectComboUnits.includes(e.unitPrice)) {
          selectComboUnits.push(e.unitPrice)
        }
      })

      comboList.map(e => {
        if (!selectCombos.includes(e.name)) {
          selectCombos.push(e.name)
        }
      })
      selectComboUnits.map(e => {
        selectComboUnitsV.push({
          label: e + '元',
          value: e
        })
      })

      selectCombos.map(e => {
        selectCombosV.push({
          label: e,
          value: e
        })
      })
      comboUnit.value = selectComboUnitsV
      combos.value = selectCombosV
    }

    return {
      modelRef,
      validateInfos,
      allCombos,
      combos,
      combo,
      sexes: [
        {
          label: '先生',
          value: 1
        },
        {
          label: '女士',
          value: 2
        }
      ],
      peoples,
      onSubScribe,
      comboUnit,
      onChangeComboUnit,
      onChangeCombo,
      onChangePeoples,
      submiting
    }
  },

  created () {
    this.onGetAllCombos()
    this.getStoreInfo()
  },

  methods: {
    async onGetAllCombos () {
      const list =
        this.$store.state.data.ALL_COMBOS.length > 0
          ? this.$store.state.data.ALL_COMBOS
          : await this.$store.dispatch('GetAllCombos')
      this.allCombos = list

      // const peoples = []
      const comboUnit = []
      const len = list.length
      // for (let i = 0; i < len; i++) {
      //   if (!peoples.includes(list[i].peopleCount)) {
      //     peoples.push(list[i].peopleCount)
      //   }
      // }

      for (let i = 0; i < len; i++) {
        if (!comboUnit.includes(list[i].unitPrice)) {
          comboUnit.push(list[i].unitPrice)
        }
      }

      // const selectPeoples = []
      const selectCombos = []

      // peoples.map((e) => {
      //   selectPeoples.push({
      //     label: e + '人',
      //     value: e
      //   })
      // })

      comboUnit.map((e) => {
        selectCombos.push({
          label: e + '元',
          value: e
        })
      })

      // this.peoples = selectPeoples
      this.comboUnit = selectCombos

      this.modelRef.comboPrice = comboUnit[0]

      this.onChangeComboUnit(comboUnit[0])
      this.modelRef.peopleCount = this.peoples[0].value
    },

    async getStoreInfo () {
      const store =
        this.$store.state.user.storeInfo.id
          ? this.$store.state.user.storeInfo
          : await this.$store.dispatch('GetStoreInfo', defaultConfig.store_id)

      this.store = store
    }
  }
})
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #f0f1f6;
  .top-img {
    display: block;
    width: 100%;
  }

  .sub-form {
    padding: 0.18rem;
    margin-top: -1.53rem;
    position: relative;
    padding-bottom: 1.52rem;
    z-index: 1;
    .form-part {
      background: #fff;
      border-radius: 0.2rem;
      margin-bottom: 0.3rem;
      padding: 0.15rem 0.4rem;
    }
    .sub-btn-box {
      position: fixed;
      bottom: 1rem;
      left: 0;
      width: 100%;
      padding: 0 0.4rem 0.1rem 0.4rem;
      .sub-btn {
        background: #ceb175;
        height: 0.76rem;
        border-radius: 0.1rem;
        color: #fff;
        text-align: center;
        line-height: 0.76rem;
      }
    }
  }
}
</style>
